<template lang="pug">
  div
    div.image-merch( v-for="(img, i) in photos" :key="img.id" )
      v-icon( large @click="remove(img)" ) mdi-delete-circle
      v-icon( v-if="img.alreadyLoaded" large @click="remove(img)" color="red" ) mdi-alert-circle
      div.img-conteiner
        img( :src="getimage(img)"  @click="openCarousel(i)" )
        span( v-if="img.alreadyLoaded" ) Данное фото уже загружено в систему
    v-dialog( v-model="dialogPhoto" width="unset" content-class="image-dialog" )
      v-carousel( hide-delimiters v-model="current_carousel" :continuous="false" height="auto" )
        v-carousel-item( class="text-center" v-for="(item, i) in photos" :key="i" )
          img( :src="getimage(item)" )
    v-dialog( v-model="alert" width="unset" )
      v-card
        v-card-title Вы хотите удалить фото?
        v-divider
        v-card-actions
          v-spacer
          v-btn( color="primary" text @click="yes" ) Да
          v-btn( color="primary" text @click="no" ) Нет
</template>

<script>
export default {
  props: ['photos'],
  data: function() {
    return {
      current_carousel: 1,
      dialogPhoto: false,
      alert: false,
      image: null
    }
  },
  methods: {
    openCarousel(idx) {
      this.current_carousel = idx
      this.dialogPhoto = true
    },
    getimage(image) {
      return image.get()
    },
    remove(image) {
      this.image = image
      this.alert = true
    },
    yes() {
      this.alert = false
      this.image.remove()
    },
    no() {
      this.alert = false
    }
  }
}
</script>

<style>
  .image-merch { 
    position: relative;
    cursor: pointer;
    display: inline-block; 
  }
  .image-merch .v-icon { 
    position: absolute;
    background: red; 
    border-radius: 30px;
    right: 0 
  }
  .image-merch img { 
    max-height: 100px;
    max-width: 200px 
  }
  .image-dialog {
    height: 90% !important;
  }
  .image-dialog .v-window, .image-dialog .v-window__container, .image-dialog .v-window-item, .image-dialog .v-window-item div {
    height: 100% !important;
    width: 100% !important;
  }
  .image-dialog img {
    max-width: 100%;
    max-height: 100%;
  }
  .image-dialog .v-window__prev {
    position: fixed;
    left: 20px;
  }
  .image-dialog .v-window__next {
    position: fixed;
    right: 20px;
  }
  .img-conteiner {
    display: flex;
    flex-direction: column;
    color: red;
    max-width: 200px 
  }
</style>